<template>
  <PageContent
    class="fiche-liste"
    :nav-titles="navTitles"
    :prev-page="prevPage"
  >
    <template
      v-if="!isLoading"
      #header-right
    >
      <ButtonGroup>
        <ButtonClassic
          v-matomo-log-click="['panier_pdf', ]"
          balise-type="a"
          variant="solid"
          icon="right"
          :ext-link="urlPdf"
          :disabled="!objectInfos?.lignes?.length"
        >
          <template #right-icon>
            <IconPDF class="icon-medium" />
          </template>
        </ButtonClassic>
        <ButtonClassic
          variant="solid"
          icon="right"
          :disabled="!objectInfos?.lignes?.length"
          @click="
            copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)
          "
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="!isLoading && objectInfos?.lignes?.length"
      #action-bar
    >
      <div
        id="zone_buttons"
        class="flex-vcenter"
      >
        <div
          v-if="canCreateDevis"
          v-tooltip="{
            content: tooltipDisabledTitle,
            disabled: !toDevisToCommandeDisabled,
          }"
        >
          <ButtonClassic
            v-matomo-log-click="['liste_to_devis_haut', ]"
            variant="solid"
            color="primary"
            :label="$t('action.passer-en-devis')"
            icon="right"
            :disabled="isImpersonating || toDevisToCommandeDisabled"
            @click="handleFirstModalToOpen('devis')"
          >
            <template #right-icon>
              <IconFileDevis />
            </template>
          </ButtonClassic>
        </div>
        <div
          v-if="canOrder"
          v-tooltip="{
            content: tooltipDisabledTitle,
            disabled: !toDevisToCommandeDisabled,
          }"
        >
          <ButtonClassic
            v-matomo-log-click="['liste_to_commande_haut', ]"
            :label="$t('action.commander')"
            color="secondary"
            variant="solid"
            :disabled="isImpersonating || toDevisToCommandeDisabled"
            icon="right"
            @click="handleFirstModalToOpen('commande')"
          >
            <template #right-icon>
              <IconPanierFleche />
            </template>
          </ButtonClassic>
        </div>
      </div>
      <div id="zone_actions_sup">
        <p
          v-if="hasNumerique"
          class="text-small italic no-refundable"
        >
          <UisExclamationTriangle />
          {{
            $t("licence.licences-numeriques-ni-echangeables-ni-remboursables")
          }}
        </p>
        <ListeActions
          context="panier"
          variant="group"
          :actions="actions"
          @click-action="handleActions($event)"
        />
      </div>
    </template>

    <template
      v-if="!isLoading"
      #content
    >
      <template v-if="objectInfos?.lignes?.length">
        <Toast
          v-if="isCommandeClosed"
          :title="$t('toast.arret-commandes')"
          :content="$t('commande.les-commandes-sont-fermees')"
          variant="error"
          show-icon
          hide-close-btn
        />
        <BlocLignesCatalogues
          :object-infos="objectInfos"
          :catalogues="catalogues"
          :timeline="false"
          @change-line="handleChangeLine($event)"
          @delete-line="!isImpersonating ? deleteLine($event) : null"
          @sort="handleSort($event)"
        />
        <TableTotal
          :object-infos="objectInfos"
          :message-numerique="hasNumerique"
        />
      </template>

      <template v-else>
        <div class="empty-page">
          <h2 class="s4 light">
            {{ $t("panier.votre-panier-est-vide") }}
          </h2>
          <img
            :src="
              require('@lde/core_lde_vue/dist/assets/media/illus/illus_cart.svg')
            "
            :alt="$t('general.alt-image-illustration')"
          />
        </div>
      </template>

      <template v-if="objectInfos">
        <ModalListesOffresIndispos
          :lignes-indisponibles="lignesIndisponibles"
          :lignes-pas-dans-catalogue="lignesPasDansCatalogue"
        />
        <ModalDevisCommande
          source-type="liste"
          :destination-type="destinationType"
          :data="objectInfos"
          :all-cgv="allCgv"
          @bascule-liste="basculeListe($event)"
          @submit="
            $event.destinationType === 'devis' ? toDevis($event) : order($event)
          "
        />
        <ModalConfirmDeletion
          :to-delete="[objectInfos, ]"
          variant="panier"
          @confirm="viderPanier"
        />
        <ModalDuplicateDevisCommande
          :doublon-infos="doublonInfos"
          @force="forceOrder"
        />
      </template>
      <ModalAntares :commande="objectInfos" />
      <ModalListesSelection
        id="modal_listes_selection_move"
        :listes="activeLists"
        @open-creation-liste="$modal.show('modal_listes_creation')"
        @move-to-list="handleAcceptAction('merge', $event)"
      />
    </template>

    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  Toast,
  PageContent,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import TableTotal from "@/components/table/TableTotal.vue";
import ModalDevisCommande from "@/components/modals/ModalDevisCommande.vue";
import ModalConfirmDeletion from "@/components/modals/ModalConfirmDeletion.vue";
import ModalListesOffresIndispos from "@/components/modals/ModalListesOffresIndispos.vue";
import ModalAntares from "@/components/modals/ModalAntares.vue";
import ModalListesSelection from "@/components/modals/ModalListesSelection.vue";
import ModalDuplicateDevisCommande from "@/components/modals/ModalDuplicateDevisCommande.vue";

import IconFileDevis from "@/components/icons/IconFileDevis.vue";
import IconPanierFleche from "@/components/icons/IconPanierFleche.vue";

import { UilTrashAlt, UilBookmark } from "@iconscout/vue-unicons";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";
import mixinFicheListeDevisCommandes from "@/mixins/mixinFicheListeDevisCommandes";
import DuplicateInfos from "@/mixins/mixinDuplicateModal";

import config from "@/config";

import {
  confirmOrganismeOnTesting,
  createDeferredPromise,
} from "@/modules/utils";
import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Vue d'une liste en particulier avec toutes ses informations.
 */
export default {
  name: "FicheListe",
  components: {
    PageContent,
    ButtonClassic,
    Toast,
    TableTotal,
    ModalDevisCommande,
    ModalConfirmDeletion,
    ModalListesOffresIndispos,
    ModalAntares,
    ModalListesSelection,
    ModalDuplicateDevisCommande,
    InfiniteLoader,
    IconFileDevis,
    IconPanierFleche,
  },
  mixins: [mixinDisponibilite, mixinFicheListeDevisCommandes, DuplicateInfos],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevPage = from;
    });
  },
  data() {
    return {
      product: {
        offre_defaut: {},
      },
      prevPage: null,
      debounceTimer: null,
      budget: {},
      changeLineTimeout: null,
      destinationType: "devis",
      controller: new AbortController(),
      saveOrderBody: null,
    };
  },
  computed: {
    ...mapGetters([
      "organismeActuel",
      "panier",
      "user",
      "isFetchingActiveLists",
      "activeLists",
    ]),
    actions() {
      return [
        {
          slug: "vider-panier",
          label: this.$t("action.vider-mon-panier"),
          icon: UilTrashAlt,
        },
        {
          slug: "passer-en-liste",
          label: this.$t("action.ajouter-a-une-liste"),
          icon: this.isFetchingActiveLists ? InfiniteLoader : UilBookmark,
          disabled: this.isFetchingActiveLists,
        },
      ];
    },
    simpleActiveLists() {
      return this.activeLists.map((item) => ({
        label: item.libelle,
        value: item.id,
      }));
    },
    lignesIndisponibles() {
      return this.objectInfos.lignes.filter(
        (ligne) => !this.isOfferAvailable(ligne.offre),
      );
    },
    lignesPasDansCatalogue() {
      return this.objectInfos.lignes.filter(
        ({ offre }) => offre.manuel_numerique && !offre?.catalogue_actif,
      );
    },
    urlPdf() {
      return `${config.api.baseUrl}/liste/${this.panier.id}/pdf/`;
    },
    canCreateDevis() {
      return this.isAllowed("creer_devis");
    },
    canOrder() {
      return this.isAllowed("commander");
    },
    tooltipDisabledTitle() {
      let tooltip = null;
      if (this.isCommandeClosed) {
        tooltip = this.$t("commande.les-commandes-sont-fermees");
      } else if (
        this.objectInfos?.lignes.some(
          (element) => element.offre.manuel_numerique
            && element.quantite < element.offre.quantite_min
            && !element.raison_minima,
        )
      ) {
        tooltip = this.$t("liste.verifiez-minima");
      }
      return tooltip;
    },
    toDevisToCommandeDisabled() {
      return (
        this.isCommandeClosed
        || this.objectInfos?.lignes.some(
          (element) => element.offre.manuel_numerique
            && element.quantite < element.offre.quantite_min
            && !element.raison_minima,
        )
      );
    },
    selections() {
      return this.objectInfos.lignes.filter((l) => l._checked);
    },
  },
  async mounted() {
    await this.$store.dispatch("setPanier");
    this.fetchPanier();
  },
  beforeDestroy() {
    this.controller.abort();
  },
  methods: {
    /**
     * Récupère les informations du panier.
     */
    fetchPanier() {
      this.isLoading = true;
      return Api()
        .get(`/liste/${this.panier.id}/`, { signal: this.controller.signal })
        .then(({ data }) => {
          if (this.controller.signal.aborted) {
            return false;
          }

          this.objectInfos = data;
          this.objectInfos.statut_affiche = "attente_selection";
          this.fetchCgvUrl("liste");

          data.lignes.forEach((ligne) => {
            if (ligne.is_complement && !ligne.raison_minima) {
              this.$set(ligne, "raison_minima", "complement");
            }
          });

          if (this.objectInfos.depasse_dotation) {
            this.$store
              .dispatch("fetchBudgetOrganisme", this.objectInfos.organisme.id)
              .then((budget) => {
                this.budgetType = budget.type === "QUANTITE" ? "licences" : "euros";
                this.budget = budget;
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            this.isLoading = false;
          }
          return true;
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            this.$store.commit("setErrorStatus", err.response.status);
          }
        });
    },
    /**
     * Met à jour une ligne lorqu'elle change (ex : modification de la quantité).
     * @param {Object} line Ligne sélectionnée.
     */
    handleChangeLine(line) {
      this.changeLineTimeout = setTimeout(() => {
        // Recalcule les totaux
        this.objectInfos.total_ttc = 0;
        this.objectInfos.total_ht = 0;
        this.objectInfos.lignes.forEach((ligne) => {
          if (ligne.quantite) {
            this.objectInfos.total_ttc
              += Math.round(
                parseFloat(ligne.offre.prix_editeur) * ligne.quantite * 100,
              ) / 100;
            this.objectInfos.total_ht
              += Math.round(
                parseFloat(ligne.offre.prix_ht) * ligne.quantite * 100,
              ) / 100;
          }
        });

        if (line.quantite <= 0) {
          this.deleteLine(line);
        } else {
          Api()
            .patch(`/ligne_de_liste/${line.id}/`, line)
            .then((res) => {
              const index = this.objectInfos.lignes.findIndex(
                (i) => i.id === res.data.id,
              );
              this.$set(this.objectInfos.lignes, index, res.data);
              this.$toast.success({
                title: this.$t("info.ligne-bien-modifiee"),
              });
              this.$store.dispatch("setPanier");
            });
        }
      }, config.api.debounceDelay);
    },
    /**
     * Supprime des lignes de liste.
     * @param {Array} ligneIds Ids des lignes qu'on souhaite supprimer.
     * @param {String} successMsg message de succès dans le toast.
     */
    handleDeleteLines(lignesIds, successMsg) {
      Api()
        .post(`/liste/${this.objectInfos.id}/remove_lignes/`, {
          lignes_ids: lignesIds,
        })
        .then(() => {
          this.fetchPanier().then(() => {
            this.$store
              .dispatch("setPanier")
              .then(() => {
                this.$toast.success({ title: successMsg });
              })
              .catch((error) => {
                this.$toast.error({
                  title: this.$t("info.une-erreur-est-survenue"),
                  content: error,
                });
              });
          });
        });
    },
    /**
     * Supprime une ligne de la liste.
     * @param {Object} line Ligne sélectionnée.
     */
    deleteLine(line) {
      this.handleDeleteLines(
        [line.id],
        this.$t("info.ligne-bien-supprimee-de-panier"),
      );
    },
    /**
     * Vide le panier.
     */
    viderPanier() {
      this.isLoading = true;
      Api()
        .post("/liste/vider_panier/")
        .then(() => {
          this.$toast.success({ title: this.$t("panier.panier-bien-vide") });
          this.$store.dispatch("setPanier");
        })
        .catch((err) => {
          this.$toast.error({
            title: err.response.data.detail || err.response.data,
          });
        });
      this.$router.push({
        name: "home",
      });
    },
    /**
     * Transforme un panier en devis.
     * @param {Object} body Paramètres à envoyer à l'API lors du passage en devis.
     */
    toDevis(body) {
      const execute = () => {
        this.$toast.info({
          title: this.$t("liste.envoi-en-cours"),
          content: this.$t("toast.veuillez-patienter"),
        });

        Api()
          .post(`/liste/${this.objectInfos.id}/to_devis/`, {
            nom: body.libelle,
            demandes_licences: body.licencesSupp,
          })
          .then(({ data: { id } }) => {
            let title = this.$t(
              "panier.panier-soumis-aux-responsables-commandes",
            );
            if (this.hasPerm("can_transmettre_devis")) {
              title = this.$t("liste.liste-bien-passee-en-devis");
            }
            this.$toast.success({ title });

            // Vérifier si tous les produits ont été passés en devis
            const allAvailable = this.objectInfos.lignes?.every((ligne) => {
              const offre = ligne.offre;
              const product = offre.article || offre.manuel_numerique || offre.fourniture;
              return this.isProductAvailable(product);
            });

            if (!allAvailable) {
              this.$toast.warning({
                title: this.$t("liste.references-pas-pu-etre-passees-en-devis"),
              });
            }

            // Remettre à jour le panier maintenant vidé.
            this.$store.dispatch("setPanier");

            this.$router.push({
              name: "listes_devis_devis_item",
              params: { id },
            });
          })
          .catch((err) => {
            this.$toast.error({
              title:
                err.response?.data || this.$t("info.une-erreur-est-survenue"),
            });
          });
      };

      if (confirmOrganismeOnTesting()) {
        execute();
      }
    },

    /**
     * Transforme une liste en commande.
     * @param {Object} body Paramètres à envoyer à l'API lors du passage en commande.
     */
    order(body) {
      if (
        this.objectInfos.lignes?.find(({ offre }) => this.isOfferAvailable(offre))
      ) {
        const execute = () => {
          this.isLoading = true;

          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });

          const params = body;

          if (this.nameInput) {
            params.nom = this.nameInput;
          } else {
            params.nom = params.libelle;
          }

          this.saveOrderBody = params;

          Api()
            .post(`/liste/${this.objectInfos.id}/to_commande/`, params)
            .then(({ data: { id } }) => {
              this.$toast.success({
                title: this.$tc("commande.commande-validee", 1),
                content: this.$t("panier.panier-bien-passe-en-commande"),
              });

              // Remettre à jour le panier maintenant vidé.
              this.$store.dispatch("setPanier");
              this.$router.push({
                name: "commandes_factures_commandes_item",
                params: { id },
              });
            })
            .catch((err) => {
              this.isLoading = false;
              if (err.response) {
                if (!this.handleDuplicateModalToOpen(err.response.data)) {
                  this.$toast.error({
                    title:
                      err.response.data.detail
                      || this.$t("info.une-erreur-est-survenue"),
                  });
                }
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        };

        if (confirmOrganismeOnTesting()) {
          execute();
        }
      } else {
        this.$toast.error({
          title: this.$t(
            "liste.votre-liste-ne-peut-pas-etre-passee-en-commande",
          ),
        });
      }
    },

    /**
     * Affiche la modale de passage en devis/commande ou la modale des offres indispos le cas échéant.
     * @param {String} type Type vers lequel la liste va évoluer ("devis" ou "commande").
     */
    handleFirstModalToOpen(type) {
      if (
        type === "commande"
        && this.organismeActuel.id_organisme.toLowerCase() === "24c0100"
      ) {
        return this.$modal.show("modal_antares");
      }
      if (
        this.lignesIndisponibles.length
        || this.lignesPasDansCatalogue.length
      ) {
        this.$modal.show("modal_listes_offres_indispos");
      } else {
        this.destinationType = type;
        this.$modal.show("modal_devis_commande");
      }
      return true;
    },
    /**
     * Appelé lors du clic sur un bouton d'action.
     * @param {Object} action Action cliqué.
     */
    handleActions(action) {
      switch (action.slug) {
        case "vider-panier":
          this.$modal.show("modal_confirm_deletion");
          break;
        case "passer-en-liste": {
          // brackets: https://eslint.org/docs/latest/rules/no-case-declarations
          const { promise, resolve } = createDeferredPromise();
          promise.then((libelle) => {
            this.handleAcceptAction("new-liste", { libelle });
          });
          this.$store.commit("setPanierToListResolver", resolve);
          this.$modal.show("modal_listes_selection_move");
          break;
        }
        default:
          break;
      }
    },
    async handleAcceptAction(type, event) {
      let newListeId = null;

      if (type === "merge") {
        await Api()
          .post("/liste/panier_to_liste/", { listeId: event })
          .then(({ data }) => {
            newListeId = data.id;
            this.$store.dispatch("setPanier");
            this.$toast.success({
              title: this.$t("panier.votre-panier-a-bien-ete-ajoute", {
                libelle: data.libelle,
              }),
            });
          })
          .catch((err) => {
            this.inputStatus = "error";
            this.$toast.error({
              title:
                err.response?.data || this.$t("info.une-erreur-est-survenue"),
            });
          });
      } else {
        await Api()
          .post("liste/panier_to_liste/", { libelle: event.libelle })
          .then(({ data }) => {
            newListeId = data.id;
            this.$modal.hide("modal_listes_creation");
            this.$store.dispatch("setPanier");
            this.$toast.success({
              title: this.$t("liste.liste-x-bien-creee", {
                libelle: data.libelle,
              }),
            });
          })
          .catch((err) => {
            if (err.code === 409) {
              this.$toast.error({
                title: this.$t("liste.une-liste-du-meme-nom-existe-deja"),
              });
            } else {
              this.inputStatus = "error";
              this.$toast.error({
                title:
                  err.response?.data || this.$t("info.une-erreur-est-survenue"),
              });
            }
          });
      }

      if (newListeId) {
        this.$router.push({
          name: "listes_devis_listes_item",
          params: { id: newListeId },
        });
      }
    },

    forceOrder(justification) {
      const params = this.saveOrderBody;
      if (params) {
        params.force = true;
        params.justification = justification;
        this.order(params);
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_fiche_liste.scss";
</style>
